import React, {useState} from 'react'
import {useParams} from 'react-router-dom'
import ItemDetailsContent from '../itemDetailsContent/ItemDetailsContent'
import './ItemDetailsPage.scss'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'
import {NoData} from '../../../genericComponents/NoData'
import {selectCurrentUser} from '../../../user/rtkUserApi'
import {useSelector} from 'react-redux'
import {useGetItemDetailsQuery} from '../../rtkItemApi'
import {useCurrentGuildContext} from '../../../util/routing'
import ItemDetailsHeader from '../itemDetailsHeader/ItemDetailsHeader'
import BreadcrumbsSection from '../BreadcrumbSection/BreadcrumbsSection'
import {DisplaySetTabsWrapperComponent} from '../../../displaySet/DisplaySetTabsWrapperComponent'
import { IdType } from '../../../util/models/IdType'
import { DisplaySetProvider, DisplaySetContext } from '../../../displaySet/DisplaySetContext'

const ItemDetailsPage = () => {
    const params = useParams()
    const [currentGuildContext] = useCurrentGuildContext()
    const user = useSelector(selectCurrentUser)

    const guildId = currentGuildContext?.guildId
    const itemId = params?.itemId
    const {data: item, isFetching, isError} = useGetItemDetailsQuery({itemId, guildId}, {skip: !user})
    if (isFetching) return <LoadingSpinner/>

    if (!item || isError) return <NoData/>

    return (
        <div className="item-details-page details-page">
            <DisplaySetProvider displaySets={item?.displaySets}>
                <BreadcrumbsSection
                    classificationId={item.technical.id}
                    partNumber={item?.partNumber}
                />

                <ItemDetailsHeader itemDetails={item}/>
                <DisplaySetTabsWrapperComponent displaySets={item?.displaySets}
                                                defaultTabName={'Item Details'}
                >
                    <ItemDetailsContent item={item}/>
                </DisplaySetTabsWrapperComponent>
            </DisplaySetProvider>
        </div>
    )
}

export default ItemDetailsPage
