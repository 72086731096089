import {PageTitle} from '../../genericComponents/typographie/PageTitle'
import React, {useContext} from 'react'
import './DisplaySetConfigurationPage.scss'
import {Description} from '../../genericComponents/typographie/Description'
import {DisplaySetContext, DisplaySetProvider} from '../../displaySet/DisplaySetContext'
import {DisplaySetTabsWrapperComponent} from '../../displaySet/DisplaySetTabsWrapperComponent'
import {useGetDisplaySetTreeQuery} from '../../displaySet/rtkDisplaySet'
import LoadingSpinner from '../../genericComponents/LoadingSpinner'
import {DisplaySetConfigurationTab} from './DisplaySetConfigurationTreeComponent'
import {LoadingButton} from '@mui/lab'
import {DisplaySet} from '../../displaySet/model/DisplaySet'
import {useSelector} from 'react-redux'
import {selectCurrentUser, useUpdateCurrentUserInfoMutation} from '../rtkUserApi'
import {useUpdateItemListMutation} from '../../itemList/rtkItemListApi'
import {
    buildDisplaySetConfigurations,
    sanitizeAndSortDisplaySetsAndConfiguration,
    sortDisplaySetsFromConfigurations
} from '../model/DisplaySetConfiguration'
import {buildUserProfileUpdate} from '../model/User'

export const DisplaySetConfigurationPage = () => {
    const user = useSelector(selectCurrentUser)
    const {data: displaySets, isFetching: isFetchingDisplaySets} = useGetDisplaySetTreeQuery()
    const [updateUser, updateUserResult] = useUpdateCurrentUserInfoMutation()


    const sortedDisplaySets = sanitizeAndSortDisplaySetsAndConfiguration(displaySets, user?.displaySetConfigurations)

    const onSubmit = () => {
        if(!user) return
        const displaySetsConfigurations = buildDisplaySetConfigurations(sortedDisplaySets)
        const newUserProfile = buildUserProfileUpdate({user: user, displaySetConfigurations: displaySetsConfigurations})
        updateUser({user: newUserProfile})
    }

    return <div className="display-set-configuration-page">
        <div className="display-set-configuration-header">
            <PageTitle title="Customize Your Item Detail Page View"></PageTitle>
            <Description>** Adjustments to layout will apply to all items in DATX</Description>
        </div>
        <div className="instructions-wrapper">
            <img className="instructions" src="/images/user/displaySetCustomizationInstructions.svg"
                 alt="Display Set Customization Instructions"/>
        </div>

        {isFetchingDisplaySets ? <LoadingSpinner/> :
            <div className="display-sets">
                <DisplaySetProvider displaySets={sortedDisplaySets}>
                    <DisplaySetTabsWrapperComponent displaySets={sortedDisplaySets}
                                                    defaultTabName={'Item Details'}
                    >
                        <DisplaySetsConfiguration></DisplaySetsConfiguration>
                    </DisplaySetTabsWrapperComponent>
                </DisplaySetProvider>
                <LoadingButton variant="contained" color="primary" type="submit" className="save-button"
                               disabled={updateUserResult.isLoading}
                               onClick={onSubmit}>
                    Save Layout
                </LoadingButton>
            </div>}

    </div>
}

const DisplaySetsConfiguration = () => {
    const {selectedDisplaySet} = useContext(DisplaySetContext)

    if (!selectedDisplaySet) return <></>
    return <>
        <DisplaySetConfigurationTab
            key={selectedDisplaySet?.displayOrder ?? Math.random()}
            displaySet={selectedDisplaySet}
        />
    </>
}