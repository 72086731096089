import OrganizationDetailsHeader from '../organizationDetailsHeader/OrganizationDetailsHeader'
import './OrganizationDetailsPage.scss'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'
import {NoData} from '../../../genericComponents/NoData'
import {useParams} from 'react-router-dom'
import {selectCurrentUser} from '../../../user/rtkUserApi'
import {useSelector} from 'react-redux'
import {useGetOrganizationQuery} from '../../rtkOrganizationApi'
import {DisplaySetTabsWrapperComponent} from '../../../displaySet/DisplaySetTabsWrapperComponent'
import {OrganizationDetailsContent} from './OrganizationDetailsContent'
import { DisplaySetProvider } from '../../../displaySet/DisplaySetContext'

const OrganizationDetailsPage = () => {
    const params = useParams()
    const user = useSelector(selectCurrentUser)
    const organizationId = params?.organizationId
    const {data: organization, isFetching, error} = useGetOrganizationQuery(organizationId, {skip: !user})

    if (isFetching) return <LoadingSpinner />
    if (!organization) return <NoData />

    return (
        <div className="organization-details-page details-page">
            <DisplaySetProvider displaySets={organization?.displaySets}>
                <OrganizationDetailsHeader organization={organization}/>
                <DisplaySetTabsWrapperComponent displaySets={organization?.displaySets}
                                                defaultTabName={'Organization Details'}
                >
                    <OrganizationDetailsContent organization={organization} />
                </DisplaySetTabsWrapperComponent>
            </DisplaySetProvider>
        </div>
    )
}

export default OrganizationDetailsPage

