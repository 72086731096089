import React, {ReactNode, useState} from 'react'
import {DisplaySet} from '../../displaySet/model/DisplaySet'
import {DisplaySetConfigurationComponent} from './DisplaySetConfigurationComponent'
import {
    closestCenter,
    DndContext,
    KeyboardSensor,
    MouseSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core'
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable'
import type {DragEndEvent} from '@dnd-kit/core/dist/types'


export const DisplaySetConfigurationTab = ({displaySet}: {
    displaySet: DisplaySet
}) => {
    return <div className="display-set-configuration" data-testid="sub-display-sets-container">
        <DisplaySetConfigurationTable displaySet={displaySet} ></DisplaySetConfigurationTable>
    </div>
}

const DisplaySetConfigurationTable = ({displaySet}: {
    displaySet: DisplaySet
}) => {
    const [subDisplaySets, SetSubDisplaySets] = useState(displaySet.children ?? [])
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(MouseSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    )

    if (!displaySet.children) return <></>

    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event

        if (active.id === over?.id) return
        if (!active.id || !over?.id) return

        SetSubDisplaySets((items) => {
            const oldIndex = items.findIndex((ds) => ds.id === active.id)
            const newIndex = items.findIndex((ds) => ds.id === over.id)
            const newChildren = arrayMove(items, oldIndex, newIndex)
            displaySet.children = newChildren
            return newChildren
        })
    }

    return <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
    >
        <SortableContext
            items={subDisplaySets}
            strategy={verticalListSortingStrategy}
        >
            {subDisplaySets.map(subDisplaySet => <DisplaySetConfigurationComponent
                key={subDisplaySet?.id ?? Math.random()}
                displaySet={subDisplaySet}/>)}
        </SortableContext>
    </DndContext>

}