import {DisplaySet} from '../../displaySet/model/DisplaySet'
import DisplaySetTitle from '../../displaySet/DisplaySetTitle'
import React from 'react'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'

export const DisplaySetConfigurationComponent = ({displaySet}: {
    displaySet: DisplaySet
}) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        active,
    } = useSortable({id: displaySet.id})
    const displaySetLabelClassName = displaySet.label.replace(' ', '-').toLowerCase()

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const isActive = active?.id === displaySet.id

    return <div ref={setNodeRef} style={style}
                data-testid={'display-set-' + displaySet.id}
                className={'display-set-configuration-component ' + displaySetLabelClassName + (isActive ? ' active' : '')} {...attributes} {...listeners}>
        <DragIndicatorIcon className="drag-indicator"></DragIndicatorIcon>
        <DisplaySetTitle title={displaySet.label} iconName={displaySet.iconName}/>
    </div>
}