import {RouteObject} from 'react-router-dom'
import {getServiceName, ServiceEnum} from '../service/model/ServiceEnum'

export interface RouteTypeDic {
    [x: string]: RouteObject & RouteCustomType
}

export interface RouteCustomType {
    options?: {pageTitle?: string; hideSearch?: boolean; fullSize?: boolean}
    publicPath?: string
}

export const guildName = ':guildName'

export const NO_GUILD_CONTEXT = 'none'

export const guildContextPrefix = 'guild'

const buildGuildContextAwarePath = (path: string) => `/${guildContextPrefix}/:currentGuildId` + path

// If useRoutes will be used later to setup routes,
// this object can be modified into array for that purpose.
export const ROUTES: RouteTypeDic = {
    login: {path: '/login'},
    signup: {path: '/signup'},
    root: {path: '/'},
    maintenance: {publicPath: '/maintenance'},
    welcome: {path: buildGuildContextAwarePath('/welcome'), options: {hideSearch: true}},
    manageItemLists: {
        path: buildGuildContextAwarePath('/lists/manage-item-lists'),
        options: {pageTitle: 'Manage Lists'}
    },
    importItemLists: {
        path: buildGuildContextAwarePath('/lists/import'),
        options: {pageTitle: 'Import Lists'}
    },
    servicesCatalogue: {
        path: buildGuildContextAwarePath('/services'),
        options: {pageTitle: 'Service Catalogue'}
    },
    partMatchingServiceChoice: {
        path: buildGuildContextAwarePath('/services/request/part-matching'),
        options: {pageTitle: getServiceName(ServiceEnum.PART_MATCHING)}
    },
    partMatchingServicePaidForm: {
        path: buildGuildContextAwarePath('/services/request/part-matching/paid'),
        options: {pageTitle: getServiceName(ServiceEnum.PART_MATCHING)}
    },
    partMatchingServiceSelfServiceForm: {
        path: buildGuildContextAwarePath('/services/request/part-matching/self-service'),
        options: {pageTitle: getServiceName(ServiceEnum.PART_MATCHING)}
    },
    newManufacturerPartsServiceForm: {
        path: buildGuildContextAwarePath('/services/request/new-parts/new-manufacturer-parts'),
        options: {pageTitle: getServiceName(ServiceEnum.NEW_PARTS)}
    },
    newCompanyPartsServiceForm: {
        path: buildGuildContextAwarePath('/services/request/new-parts/new-company-parts'),
        options: {pageTitle: getServiceName(ServiceEnum.NEW_PARTS)}
    },
    newPartsServiceForm: {
        path: buildGuildContextAwarePath('/services/request/new-parts'),
        options: {pageTitle: getServiceName(ServiceEnum.NEW_PARTS)}
    },
    accessToInformationPackageServiceForm: {
        path: buildGuildContextAwarePath('/services/request/access-information-package'),
        options: {pageTitle: getServiceName(ServiceEnum.SUBSCRIBE_INFORMATION_PACKAGE)}
    },
    informationPackageMonitoring: {
        path: buildGuildContextAwarePath('/services/request/information-package-monitoring'),
        options: {pageTitle: getServiceName(ServiceEnum.ITEM_MONITORING)}
    },
    cadModelCreation: {
        path: buildGuildContextAwarePath('/services/request/cad-model-creation'),
        options: {pageTitle: getServiceName(ServiceEnum.CAD_MODEL)}
    },
    partExtractionServiceChoice: {
        path: buildGuildContextAwarePath('/services/request/item-extraction'),
        options: {pageTitle: getServiceName(ServiceEnum.EXTRACTION)}
    },
    oneTimeExtractionServiceForm: {
        path: buildGuildContextAwarePath('/services/request/item-extraction/one-time'),
        options: {pageTitle: getServiceName(ServiceEnum.ONE_TIME_EXTRACTION)}
    },
    recurringExtractionServiceForm: {
        path: buildGuildContextAwarePath('/services/request/item-extraction/recurring'),
        options: {pageTitle: getServiceName(ServiceEnum.RECURRING_EXTRACTION)}
    },
    managePrivateAttribute: {
        path: buildGuildContextAwarePath('/services/request/manage-private-attributes'),
        options: {pageTitle: getServiceName(ServiceEnum.MANAGE_PRIVATE_ATTRIBUTES)}
    },
    selectTypeCreationPrivateAttribute: {
        path: buildGuildContextAwarePath('/services/request/manage-private-attributes/select-type-creation'),
        options: {pageTitle: getServiceName(ServiceEnum.MANAGE_PRIVATE_ATTRIBUTES)}
    },
    createStringAttribute: {
        path: buildGuildContextAwarePath('/services/request/manage-private-attributes/create-string'),
        options: {pageTitle: getServiceName(ServiceEnum.MANAGE_PRIVATE_ATTRIBUTES)}
    },
    selectTypeUpdatePrivateAttribute: {
        path: buildGuildContextAwarePath('/services/request/manage-private-attributes/select-type-update'),
        options: {pageTitle: getServiceName(ServiceEnum.MANAGE_PRIVATE_ATTRIBUTES)}
    },
    updateStringAttribute: {
        path: buildGuildContextAwarePath('/services/request/manage-private-attributes/update-string'),
        options: {pageTitle: getServiceName(ServiceEnum.MANAGE_PRIVATE_ATTRIBUTES)}
    },
    createDocumentAttribute: {
        path: buildGuildContextAwarePath('/services/request/manage-private-attributes/create-document'),
        options: {pageTitle: getServiceName(ServiceEnum.MANAGE_PRIVATE_ATTRIBUTES)}
    },
    detailItemList: {
        path: buildGuildContextAwarePath('/lists/:itemListId'),
        options: {pageTitle: 'List Details'}
    },
    detailItem: {
        path: buildGuildContextAwarePath('/items/:itemId'),
        options: {pageTitle: 'Item Details'}
    },
    organizationDetails: {
        path: buildGuildContextAwarePath('/organizations/:organizationId'),
        options: {pageTitle: 'Organization Details'}
    },
    selectionListDetails: {path: buildGuildContextAwarePath('/lists/selection-list-details/:id')},
    search: {
        path: buildGuildContextAwarePath('/search'),
        options: {pageTitle: 'Item Search'}
    },
    searchByClassificationNodeDescendants: {
        path: buildGuildContextAwarePath('/search/classification/:classificationId'),
        options: {pageTitle: 'Item Search'}
    },
    guildCreation: {
        path: buildGuildContextAwarePath('/guilds/apply-for-new-guild'),
        options: {pageTitle: 'Create Guild'}
    },
    guildManagement: {
        path: buildGuildContextAwarePath('/guilds/manage-guild'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    manageGuildUser: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/members'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    guildDetails: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/details'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    guildCredit: {
        path:  buildGuildContextAwarePath('/guilds/manage-guild/credit'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    serviceRequests: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/services-requests'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    sftpAccess: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/sftp-access'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    guildServiceSubscriptions: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/services-subscriptions'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    guildActiveServiceSubscriptions: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/services-subscriptions/active'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    guildInactiveServiceSubscriptions: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/services-subscriptions/inactive'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    manageServiceSubscription: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/services-subscriptions/:serviceSubscriptionId'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    itemsSharedTo: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/item-sharing/shared-to'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    itemsSharedToOtherGuilds: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/item-sharing/shared-to/other-guilds'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    itemsSharedToMyGuild: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/item-sharing/shared-to/my-guild'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    itemsShareToOtherGuild: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/item-sharing/shared-to/other-guilds/:sharingId'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    addSharingItems: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/item-sharing/shared-to/other-guilds/:sharingId/items'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    addSharingPrivateAttributes: {
        path: buildGuildContextAwarePath(
            '/guilds/manage-guild/item-sharing/shared-to/other-guilds/:sharingId/definitions'
        ),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    manageOrganisationSharedItems: {
        path: buildGuildContextAwarePath(
            '/guilds/manage-guild/item-sharing/shared-to/other-guilds/:sharingId/manage-organisation-sharing'
        ),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    manageSpecificSharedItems: {
        path: buildGuildContextAwarePath(
            '/guilds/manage-guild/item-sharing/shared-to/other-guilds/:sharingId/manage-specific-items'
        ),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    selectItemListForSharing: {
        path: buildGuildContextAwarePath(
            '/guilds/manage-guild/item-sharing/shared-to/other-guilds/:sharingId/select-itemlist-for-sharing'
        ),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    workflowConfiguration: {
        path: buildGuildContextAwarePath('/guilds/manage-guild/workflow-configuration'),
        options: {pageTitle: 'Manage Guild', fullSize: true}
    },
    userServiceRequests: {
        path: buildGuildContextAwarePath('/services/user-service-requests'),
        options: {pageTitle: 'My Service Requests'}
    },
    userServiceSubscriptions: {
        path: buildGuildContextAwarePath('/services/user-service-subscriptions'),
        options: {pageTitle: 'My Service Subscriptions'}
    },
    manageUserServiceSubscriptions: {
        path: buildGuildContextAwarePath('/services/user-service-subscriptions/:serviceSubscriptionId'),
        options: {pageTitle: 'My Service Subscriptions'}
    },
    userActiveServiceSubscriptions: {
        path: buildGuildContextAwarePath('/services/user-service-subscriptions/active'),
        options: {pageTitle: 'My Service Subscriptions'}
    },
    userInactiveServiceSubscriptions: {
        path: buildGuildContextAwarePath('/services/user-service-subscriptions/inactive'),
        options: {pageTitle: 'My Service Subscriptions'}
    },
    displaySetConfiguration: {
        path: '/user/display-set-configuration',
        options: {pageTitle: 'Display Set Configuration'}
    },
    legal: {
        path: buildGuildContextAwarePath('/legal'),
        publicPath: '/legal'
    },
    termsOfUse: {
        path: buildGuildContextAwarePath('/legal/termsOfUse'),
        publicPath: '/legal/termsOfUse',
        options: {pageTitle: 'Terms and Conditions'}
    },
    termsOfSales: {
        path: buildGuildContextAwarePath('/legal/termsOfSales'),
        publicPath: '/legal/termsOfSales',
        options: {pageTitle: 'Terms and Conditions'}
    },
    legalNotices: {
        path: buildGuildContextAwarePath('/legal/legalNotices'),
        publicPath: '/legal/legalNotices',
        options: {pageTitle: 'Terms and Conditions'}
    },
    privacyPolicy: {
        path: buildGuildContextAwarePath('/legal/privacyPolicy'),
        publicPath: '/legal/privacyPolicy',
        options: {pageTitle: 'Terms and Conditions'}
    },
    cookiePolicy: {
        path: buildGuildContextAwarePath('/legal/cookiePolicy'),
        publicPath: '/legal/cookiePolicy',
        options: {pageTitle: 'Terms and Conditions'}
    },
    extractionDownload: {
        path: buildGuildContextAwarePath('/services-requests/:serviceRequestsId/extraction-download')
    },
    any: {
        path: buildGuildContextAwarePath('/*')
    }
}
